html {
  --scrollbarBG: #d2d3d3;
  --thumbBG: #eff0f0;
}
*::-webkit-scrollbar {
  width: 11px;
}
* {
  scrollbar-width: 11px;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
*::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
*::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 2px solid var(--scrollbarBG);
}
